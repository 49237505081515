import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import notification from './modules/notification';
import filterTray from './modules/filterTray';
import layout from './modules/layout';
import users from './modules/users';
import clubs from './modules/clubs';
import userSchedule from './modules/userSchedule';
import onboardingPlans from './modules/onboardingPlans';
import underwritingPlans from './modules/underwritingPlans';
import contracts from './modules/contracts';
import usage from './modules/usage';
import activities from './modules/activities';
import onboardingReports from './modules/onboardingReports';
import financialTracking from './modules/financialTracking';
import secondaryTab from './modules/secondaryTab';


Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    notification,
    filterTray,
    layout,
    users,
    clubs,
    userSchedule,
    onboardingPlans,
    underwritingPlans,
    contracts,
    usage,
    activities,
    onboardingReports,
    financialTracking,
    secondaryTab,
  },
  plugins: [createPersistedState({
    paths: [
      'auth.justLoggedIn',
    ],
  })],
  strict: process.env.NODE_ENV !== 'production',
});

export { store as default };
