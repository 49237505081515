<template>
  <div>
    <span class="table-header">Notes</span>
    <div class="notes">
      <b-field
        :type="noteInvalid ? 'is-danger' : ''"
        label="">
        <b-input v-model="noteText" type="textarea" />
      </b-field>
      <div class="notes__flex">
        <div v-if="noteText.length > 3900">
          <span :class="noteInvalid ? 'notes__note-invalid' : ''">
            {{ noteText.length }}
          </span> / 4000
        </div>
        <b-button :disabled="!canSave"
          @click="addNote"
          class="notes__button"
          type="is-primary">Add Note</b-button>
      </div>
      <div v-if="editing">
        <template  v-for="note in orderedNotes">
          <Note :note="note"
            :key="note.id"
            @delete="confirmDeleteNote($event)" />
        </template>
      </div>
      <div v-else>
        <template  v-for="note in newNotes">
          <Note :note="note"
            :key="note.created_at"
            @delete="confirmDeleteNote($event)" />
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import Note from '@/components/common/notes/Note';
import api from '@/http-playmetrics';
import { SYSTEM_CROSSBAR, SYSTEM_PLAYMETRICS } from '@/models/FinancialTracker';

export default {
  components: { Note },
  props: {
    club: { type: Object, default: (() => {}) },
    system: { type: String, default: SYSTEM_PLAYMETRICS },
  },
  data() {
    return {
      newNotes: [],
      noteText: '',
    };
  },
  computed: {
    systemURL() {
      if (this.system === SYSTEM_CROSSBAR) {
        return '/cst/crossbar';
      }
      return '/cst/club_internals';
    },
    noteInvalid() {
      return this.noteText.length > 4000;
    },
    canSave() {
      return !this.noteInvalid && this.noteText.trim() !== '';
    },
    orderedNotes() {
      if (this.notes) {
        const notesCopy = [...this.notes];
        return notesCopy.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      return [];
    },
    notes() {
      return this.club.notes;
    },
    clubID() {
      return this.club.id;
    },
    editing() {
      return !!this.clubID;
    },
  },
  methods: {
    deleteNewNotes() {
      this.newNotes.forEach(note => this.deleteNote(note));
    },
    async addNote() {
      this.newNotes.push({ note: this.noteText, created_at: (new Date()).toISOString(), newNote: true });
      if (this.editing) {
        await this.submitNote(this.clubID);
      }
      this.noteText = '';
    },
    confirmDeleteNote($event) {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Note',
        message: 'Are you sure you want to delete this note?',
        title: 'Delete Note',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => {
          if (this.editing) {
            this.deleteNote($event);
          } else {
            this.newNotes.pop();
          }
        },
      });
    },
    deleteNote($event) {
      if (this.editing && !$event?.newNote) {
        api().delete(`${this.systemURL}/${this.club.id}/notes/${$event.id}/`).then((res) => {
          if (res.status === 200) {
            this.$emit('noteDeleted', this.club.id);
          }
        });
      } else {
        this.notes = this.notes.filter(note => note.created_at !== $event.created_at);
      }
    },
    submitNote(id) {
      const notesCopy = this.newNotes.filter(note => !note.id).map(n => ({
        note: n.note,
      }));
      for (let i = 0; i < notesCopy.length; i += 1) {
        api().post(`${this.systemURL}/${this.club.id}/notes`, notesCopy[i]).then((res) => {
          if (res.status === 200) {
            this.newNotes.push(res.data);
            this.$emit('noteCreated', id);
          }
        });
      }
      this.newNotes = this.newNotes.filter(note => note.id);
    },
  },
};
</script>

<style lang="sass" scoped>
.notes
  &__button
    display: block
    margin-left: auto
    margin-right: 0
  &__flex
    display: flex
  &__note-:invalid
    color: $red
</style>
