import { shallowRef } from 'vue';

const state = {
  secondaryTab: shallowRef({}),
};

const getters = {
  secondaryTab(state) {
    // return state.secondaryTab[route];

    function f(route) {
      return state.secondaryTab[route];
    }
    return f;
  },
};

// mutations
const mutations = {
  setSecondaryTab(state, map) {
    state.secondaryTab[map.route] = map.tab;
  },
  clearSecondaryTab(state, route) {
    delete state.secondaryTab[route];
  },
};

const actions = {
  createSecondaryTab(context, route, tab) {
    context.commit('setSecondaryTab', route, tab);
  },
  clearSecondaryTab(context, route) {
    context.commit('clearSecondaryTab', route);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
