import { render, staticRenderFns } from "./ClubInternalNotes.vue?vue&type=template&id=10055586&scoped=true"
import script from "./ClubInternalNotes.vue?vue&type=script&lang=js"
export * from "./ClubInternalNotes.vue?vue&type=script&lang=js"
import style0 from "./ClubInternalNotes.vue?vue&type=style&index=0&id=10055586&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10055586",
  null
  
)

export default component.exports